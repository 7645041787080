import { themeAlwaysWin } from "~styles/themes/fakeBrands/theme_alwaysWin.css";
import { themeCryptoNext } from "~styles/themes/fakeBrands/theme_cryptoNext.css";
import { themeDefault } from "~styles/themes/fakeBrands/theme_default.css";
import { themeGetMoney } from "~styles/themes/fakeBrands/theme_getMoney.css";
import { themeJustShop } from "~styles/themes/fakeBrands/theme_justShop.css";
import { themeNiceTrade } from "~styles/themes/fakeBrands/theme_niceTrade.css";
import { themeTravelNow } from "~styles/themes/fakeBrands/theme_travelNow.css";

export const THEME_CLASS_MAP = {
  justShop: themeJustShop,
  alwaysWin: themeAlwaysWin,
  niceTrade: themeNiceTrade,
  getMoney: themeGetMoney,
  cryptoNext: themeCryptoNext,
  travelNow: themeTravelNow,
  default: themeDefault,
} as const;

export type FakeBrands = keyof typeof THEME_CLASS_MAP;
